import * as React from "react"
import Seo from "src/components/seo"
import Layout from "src/components/layout"
import * as styles from "src/scss/_news.module.scss"

const NewsPage = () => (
  <Layout>
    <Seo title="お知らせ" />

    <div className="pageWrapper">
      <article className={styles.newsWrapper}>
        <div className={styles.newsTitleWrapper}>
          <time dateTime="2025-04-01">2025.04.01</time>
          <h1>長岡事務所移転のお知らせ</h1>
        </div>

        <section>
          <p>
            平素は格別のお引き立てを賜り厚く御礼申し上げます。
            <br />
            さて、このたびサービスをより一層強化充実するために長岡ＣＳセンターを下記の通り移転することになりました。
            <br />
            これを機に、さらに皆様方のご愛顧を得られますよう専心努力いたす所存でございますので、今後ともなお一層のご支援を賜りますようお願い申し上げます。
          </p>
        </section>

        <section>
          <p>
            【移転先】
            <br />
            郵便番号：〒940-0098
            <br />
            住所：新潟県長岡市中島4丁目12番5号 3F
            <br />
            電話：0258-25-6310（従来通り）
            <br />
            FAX：0258-25-6309（従来通り）
            <br />
          </p>
        </section>

        <section>
          <p>
            【営業開始日】
            <br />
            2025年4月21日（月）
          </p>
        </section>

        <section>
          <iframe
            className={styles.googleMap}
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d791.8379505708967!2d138.8457619!3d37.4524148!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5ff5a9ae121280b3%3A0xcc870929fae5239b!2z44CSOTQwLTAwOTQg5paw5r2f55yM6ZW35bKh5biC5Lit5bO277yU5LiB55uu77yR77yS4oiS77yV!5e0!3m2!1sja!2sjp!4v1744260932453!5m2!1sja!2sjp"
            title="CS長岡のGoogleMap情報"
            loading="lazy"
            rel="noopener noreferrer"
          ></iframe>
        </section>
      </article>
    </div>
  </Layout>
)

export default NewsPage
